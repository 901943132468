define("discourse/plugins/discourse-locations/discourse/components/modal/add-location", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "I18n", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _service, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _descriptor9, _descriptor10;
  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }
  function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'transform-class-properties is enabled and runs after the decorators transform.'); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
    class="add-location add-location-modal"
    @title={{this.title}}
  >
    <LocationForm
      @street={{this.street}}
      @neighbourhood={{this.neighbourhood}}
      @postalcode={{this.postalcode}}
      @city={{this.city}}
      @state={{this.state}}
      @countrycode={{this.countrycode}}
      @geoLocation={{this.geoLocation}}
      @rawLocation={{this.rawLocation}}
      @inputFields={{this.inputFields}}
      @searchOnInit={{this.searchOnInit}}
      @setGeoLocation={{this.setGeoLocation}}
      @searchError={{this.searchError}}
    />
    <hr />
    <div class="control-group">
      <label class="control-label">{{i18n "location.name.title"}}</label>
      <div class="controls">
        <Input
          @type="text"
          @value={{this.name}}
          class="input-xxlarge input-location location-name"
        />
      </div>
      <div class="instructions">{{i18n "location.name.desc"}}</div>
    </div>
    <div class="modal-footer">
      <DButton
        id="save-location"
        @action={{action "submit"}}
        @label="location.done"
        @class="btn-primary"
        @disabled={{this.submitDisabled}}
      />
      <DButton
        id="clear-location"
        @class="clear"
        @action={{action "clear"}}
        @label="location.clear"
      />
    </div>
  </DModal>
  */
  {
    "id": "+ssTdABt",
    "block": "[[[8,[39,0],[[24,0,\"add-location add-location-modal\"]],[[\"@closeModal\",\"@flash\",\"@title\"],[[30,1],[30,0,[\"flash\"]],[30,0,[\"title\"]]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,1],null,[[\"@street\",\"@neighbourhood\",\"@postalcode\",\"@city\",\"@state\",\"@countrycode\",\"@geoLocation\",\"@rawLocation\",\"@inputFields\",\"@searchOnInit\",\"@setGeoLocation\",\"@searchError\"],[[30,0,[\"street\"]],[30,0,[\"neighbourhood\"]],[30,0,[\"postalcode\"]],[30,0,[\"city\"]],[30,0,[\"state\"]],[30,0,[\"countrycode\"]],[30,0,[\"geoLocation\"]],[30,0,[\"rawLocation\"]],[30,0,[\"inputFields\"]],[30,0,[\"searchOnInit\"]],[30,0,[\"setGeoLocation\"]],[30,0,[\"searchError\"]]]],null],[1,\"\\n  \"],[10,\"hr\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,2],[\"location.name.title\"],null]],[13],[1,\"\\n    \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n      \"],[8,[39,3],[[24,0,\"input-xxlarge input-location location-name\"]],[[\"@type\",\"@value\"],[\"text\",[30,0,[\"name\"]]]],null],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"instructions\"],[12],[1,[28,[35,2],[\"location.name.desc\"],null]],[13],[1,\"\\n  \"],[13],[1,\"\\n  \"],[10,0],[14,0,\"modal-footer\"],[12],[1,\"\\n    \"],[8,[39,4],[[24,1,\"save-location\"]],[[\"@action\",\"@label\",\"@class\",\"@disabled\"],[[28,[37,5],[[30,0],\"submit\"],null],\"location.done\",\"btn-primary\",[30,0,[\"submitDisabled\"]]]],null],[1,\"\\n    \"],[8,[39,4],[[24,1,\"clear-location\"]],[[\"@class\",\"@action\",\"@label\"],[\"clear\",[28,[37,5],[[30,0],\"clear\"],null],\"location.clear\"]],null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\"location-form\",\"i18n\",\"input\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-locations/discourse/components/modal/add-location.hbs",
    "isStrictMode": false
  });
  let AddLocationComponent = _exports.default = (_dec = (0, _object.computed)(), _dec2 = (0, _object.computed)("geoLocation"), (_class = class AddLocationComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "siteSettings", _descriptor, this);
      _initializerDefineProperty(this, "flash", _descriptor2, this);
      _initializerDefineProperty(this, "searchOnInit", _descriptor3, this);
      _initializerDefineProperty(this, "name", _descriptor4, this);
      _initializerDefineProperty(this, "street", _descriptor5, this);
      _initializerDefineProperty(this, "postalcode", _descriptor6, this);
      _initializerDefineProperty(this, "city", _descriptor7, this);
      _initializerDefineProperty(this, "countrycode", _descriptor8, this);
      _initializerDefineProperty(this, "geoLocation", _descriptor9, this);
      _initializerDefineProperty(this, "rawLocation", _descriptor10, this);
      _defineProperty(this, "title", _I18n.default.t("composer.location.title"));
      const location = this.args.model.location;
      this.countrycode = this.siteSettings.location_country_default;
      if (location) {
        this.name = location.name;
        this.street = location.street;
        this.neighbourhood = location.neighbourhood;
        this.postalcode = location.postalcode;
        this.city = location.city;
        this.state = location.state;
        this.countrycode = location.countrycode;
        this.geoLocation = location.geo_location;
        this.rawLocation = location.raw;
      }
    }
    get inputFields() {
      return this.siteSettings.location_input_fields.split("|");
    }
    get submitDisabled() {
      return this.siteSettings.location_geocoding === "required" && !this.geoLocation;
    }
    clearModal() {
      this.name = null;
      this.street = null;
      this.neighbourhood = null;
      this.postalcode = null;
      this.city = null;
      this.state = null;
      this.countrycode = null;
      this.geoLocation = {
        lat: "",
        lon: ""
      };
      this.rawLocation = null;
    }
    clear() {
      this.clearModal();
      this.args.model.update(null);
      this.args.closeModal();
    }
    submit() {
      if (this.submitDisabled) {
        return;
      }
      let location = {};
      const geocodingEnabled = this.siteSettings.location_geocoding !== "none";
      const inputFieldsEnabled = this.siteSettings.location_input_fields_enabled;
      const inputFields = this.inputFields;
      const hasCoordinates = inputFields.indexOf("coordinates") > -1;
      if (!geocodingEnabled && !inputFieldsEnabled) {
        location["raw"] = this.rawLocation;
      }
      if (inputFieldsEnabled) {
        const nonGeoProps = inputFields.filter(f => f !== "coordinates");
        nonGeoProps.forEach(f => {
          location[f] = this[f];
        });
      }
      if (geocodingEnabled || hasCoordinates) {
        const geoLocation = this.geoLocation;
        if (geoLocation && geoLocation.lat && geoLocation.lon) {
          location["geo_location"] = geoLocation;
        }
      }
      let name = this.name;
      if (name) {
        location["name"] = name;
      }
      Object.keys(location).forEach(k => {
        if (location[k] == null || location[k] === "" || location[k] === {}) {
          delete location[k];
        }
      });
      if (Object.keys(location).length === 0) {
        location = null;
      }
      this.args.model.update(location);
      this.clearModal();
      this.args.closeModal();
    }
    setGeoLocation(gl) {
      this.name = gl.name;
      this.street = gl.street;
      this.neighbourhood = gl.neighbourhood;
      this.postalcode = gl.postalcode;
      this.city = gl.city;
      this.state = gl.state;
      this.geoLocation = {
        lat: gl.lat,
        lon: gl.lon
      };
      this.countrycode = gl.countrycode;
      this.rawLocation = gl.address;
    }
    searchError(error) {
      this.flash = error;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "siteSettings", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "flash", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return this.args.model?.flash;
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "searchOnInit", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return false;
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "name", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "street", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "postalcode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "city", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "countrycode", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor9 = _applyDecoratedDescriptor(_class.prototype, "geoLocation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return {
        lat: "",
        lon: ""
      };
    }
  }), _descriptor10 = _applyDecoratedDescriptor(_class.prototype, "rawLocation", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "inputFields", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "inputFields"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submitDisabled", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "submitDisabled"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clearModal", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clearModal"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clear", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clear"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setGeoLocation", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setGeoLocation"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "searchError", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "searchError"), _class.prototype)), _class));
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AddLocationComponent);
});